import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const productTemplate = ({ data, pageContext }) => {
  return (
    <Layout>
      <section>
        <div className="text-center text-4xl mt-16 mb-8">
          {pageContext.tagname}
        </div>
        <hr className="w-2/3 m-auto mb-16"></hr>
        <div className="grid grid-cols-3 gap-4">
          {data.allContentfulProduct.edges.map(({ node }) => (
            <Link to={`/product/${node.slug}`} key={node.toString()}>
              <div className="mb-0 shadow-none hover:shadow-lg">
                <img src={node.eyeCatch.fluid.srcWebp} alt="thumbnail" />
                <div className="text-center pb-8">
                  <span className="block text-sm px-4 py-0">
                    {node.productName}
                  </span>
                  <span className="block text-sm px-4 py-0">
                    {node.veneer}|{node.lot}
                  </span>
                  <span className="block text-sm px-4 py-0">{node.size}</span>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query($tagid: String!) {
    allContentfulProduct(filter: { tag: { id: { eq: $tagid } } }) {
      edges {
        node {
          id
          tag {
            tag
          }
          productName
          veneer
          lot
          size
          slug
          eyeCatch {
            fluid(maxWidth: 1800) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`

export default productTemplate
